var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "btnTOP" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.configSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "生产仓库设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c("div", { staticClass: "x-f marB10" }, [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", [_vm._v("生产工厂")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth marL5",
                            attrs: {
                              disabled: _vm.isEdit25 == "1",
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                              "value-key": "warehouseNumber",
                              size: "mini",
                            },
                            on: { change: _vm.produceStoreChange },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_25,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_GODOWN_CONFIG_25",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25",
                            },
                          },
                          _vm._l(_vm.deptList, function (item) {
                            return _c("el-option", {
                              key: item.deptId,
                              attrs: {
                                label: item.deptName,
                                value: item.deptId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marL20" },
                      [
                        _c("span", { staticStyle: { "margin-left": "14px" } }, [
                          _vm._v("成品仓库"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth marL5",
                            attrs: {
                              disabled:
                                _vm.isEdit21 == "1" ||
                                !_vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_25,
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                              size: "mini",
                            },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_21,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_GODOWN_CONFIG_21",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_21",
                            },
                          },
                          _vm._l(_vm.storeList, function (item) {
                            return _c("el-option", {
                              key: item.storeId,
                              attrs: {
                                label: item.storeName,
                                value: item.storeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marL20" },
                      [
                        _c("span", [_vm._v("配料间")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth marL5",
                            attrs: {
                              disabled:
                                _vm.isEdit22 == "1" ||
                                !_vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_25,
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                              "value-key": "warehouseNumber",
                              size: "mini",
                            },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_22,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_GODOWN_CONFIG_22",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_22",
                            },
                          },
                          _vm._l(_vm.storeList, function (item) {
                            return _c("el-option", {
                              key: item.storeId,
                              attrs: {
                                label: item.storeName,
                                value: item.storeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "x-f" }, [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("原料仓库")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth marL5",
                            attrs: {
                              disabled:
                                _vm.isEdit23 == "1" ||
                                !_vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_25,
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                              "value-key": "warehouseNumber",
                              size: "mini",
                            },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_23,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_GODOWN_CONFIG_23",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_23",
                            },
                          },
                          _vm._l(_vm.storeList, function (item) {
                            return _c("el-option", {
                              key: item.storeId,
                              attrs: {
                                label: item.storeName,
                                value: item.storeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marL20" },
                      [
                        _c("span", [_vm._v("半成品仓库")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth marL5",
                            attrs: {
                              disabled:
                                _vm.isEdit24 == "1" ||
                                !_vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_25,
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                              "value-key": "warehouseNumber",
                              size: "mini",
                            },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PRODUCE_GODOWN_CONFIG_24,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_GODOWN_CONFIG_24",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_24",
                            },
                          },
                          _vm._l(_vm.storeList, function (item) {
                            return _c("el-option", {
                              key: item.storeId,
                              attrs: {
                                label: item.storeName,
                                value: item.storeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "生产参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "div",
                      [
                        _vm._v(" 控制门店订货单每天做 "),
                        _c("el-input", {
                          staticClass: "input underline-input",
                          staticStyle: { width: "60px" },
                          attrs: { size: "mini", min: 1, type: "number" },
                          model: {
                            value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_11,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "system_BUSINESS_PRODUCE_CONFIG_11",
                                $$v
                              )
                            },
                            expression:
                              "form.system_BUSINESS_PRODUCE_CONFIG_11",
                          },
                        }),
                        _vm._v("张，(注:不填默认不控制) "),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT15" },
                      [
                        _vm._v(" 生产计划单分生产组制作 "),
                        _c("el-switch", {
                          staticClass: "marL20",
                          attrs: { "active-value": "Y", "inactive-value": "N" },
                          model: {
                            value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_12,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "system_BUSINESS_PRODUCE_CONFIG_12",
                                $$v
                              )
                            },
                            expression:
                              "form.system_BUSINESS_PRODUCE_CONFIG_12",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT15" },
                      [
                        _vm._v(" 允许多次入库 "),
                        _c("el-switch", {
                          staticClass: "marL20",
                          attrs: { "active-value": "Y", "inactive-value": "N" },
                          model: {
                            value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_14,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "system_BUSINESS_PRODUCE_CONFIG_14",
                                $$v
                              )
                            },
                            expression:
                              "form.system_BUSINESS_PRODUCE_CONFIG_14",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT15" },
                      [
                        _vm._v(" 允许多次分货 "),
                        _c("el-switch", {
                          staticClass: "marL20",
                          attrs: { "active-value": "Y", "inactive-value": "N" },
                          model: {
                            value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_15,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "system_BUSINESS_PRODUCE_CONFIG_15",
                                $$v
                              )
                            },
                            expression:
                              "form.system_BUSINESS_PRODUCE_CONFIG_15",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT15" },
                      [
                        _vm._v(" 产品入库单审核前强制进行质检 "),
                        _c("el-switch", {
                          staticClass: "marL20",
                          attrs: { "active-value": "Y", "inactive-value": "N" },
                          model: {
                            value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_16,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "system_BUSINESS_PRODUCE_CONFIG_16",
                                $$v
                              )
                            },
                            expression:
                              "form.system_BUSINESS_PRODUCE_CONFIG_16",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT15" },
                      [
                        _vm._v(" 智能配货优先级规则 "),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.form.system_BUSINESS_PRODUCE_CONFIG_120,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_CONFIG_120",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_CONFIG_120",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("按指定排序配货"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("按订货排名配货"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form",
                      [
                        _c("el-form-item", [
                          _c("div", { staticClass: "marT20" }, [
                            _c("span", [
                              _vm._v(
                                "若您允许生产计划单的数量大于销售订单的数量，请勾选"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PRODUCE_CONFIG_17,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PRODUCE_CONFIG_17",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_PRODUCE_CONFIG_17",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "允许生产计划单数量大于销售订单可生产数量"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-form-item", [
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                "若您允许生产领料单的数量大于生产计划单的计划投料数量，请勾选"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PRODUCE_CONFIG_18,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PRODUCE_CONFIG_18",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_PRODUCE_CONFIG_18",
                                    },
                                  },
                                  [_vm._v("允许数量大于计划投料数量")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-form-item", {
                          staticClass: "compact-form-item",
                          attrs: {
                            label:
                              "若您希望控制产品入库前必须先领料，请选择控制强度及控制项",
                          },
                        }),
                        _c(
                          "el-radio",
                          {
                            staticClass: "marT10",
                            attrs: { label: 1 },
                            model: {
                              value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_19,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_CONFIG_19",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_CONFIG_19",
                            },
                          },
                          [_vm._v("不控制")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_19,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_CONFIG_19",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_CONFIG_19",
                            },
                          },
                          [_vm._v("提示，允许继续入库")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 3 },
                            model: {
                              value: _vm.form.system_BUSINESS_PRODUCE_CONFIG_19,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PRODUCE_CONFIG_19",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PRODUCE_CONFIG_19",
                            },
                          },
                          [_vm._v("提示，不允许继续入库")]
                        ),
                        _c("el-form-item", [
                          _c("div", { staticClass: "marT15" }, [
                            _c("span", [
                              _vm._v(
                                "若您允许产品入库单的数量大于生计划单的数量，请勾选"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PRODUCE_CONFIG_110,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PRODUCE_CONFIG_110",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_PRODUCE_CONFIG_110",
                                    },
                                  },
                                  [_vm._v("允许入库数量大于生产计划数量")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-form-item", [
                          _c("div", [
                            _c("span", [_vm._v("当没有分货对象时，请勾选")]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PRODUCE_CONFIG_130,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PRODUCE_CONFIG_130",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_PRODUCE_CONFIG_130",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("自动增加门店"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("手动增加门店"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-form-item", [
                          _c("div", [
                            _c("span", [_vm._v("原料成本价计算规则，请勾选")]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PRODUCE_CONFIG_140,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PRODUCE_CONFIG_140",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_PRODUCE_CONFIG_140",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("标准进价"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("最新进价"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "3" } }, [
                                      _vm._v("最新成本价"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm.form.system_BUSINESS_PRODUCE_CONFIG_140 != 1
                          ? _c("el-form-item", [
                              _c(
                                "div",
                                [
                                  _vm._v(" 配方成本自动更新 "),
                                  _c("el-switch", {
                                    staticClass: "marL20 marR20",
                                    attrs: {
                                      "active-value": "1",
                                      "inactive-value": "0",
                                    },
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PRODUCE_CONFIG_150,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PRODUCE_CONFIG_150",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_PRODUCE_CONFIG_150",
                                    },
                                  }),
                                  _c("span", { staticClass: "annotateGrey" }, [
                                    _vm._v(
                                      "仅支持原料成本价计算规则为：【最新进价、最新成本价】"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }